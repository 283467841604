import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import NavBar from './NavBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import MainLogo from '../marketLogo.jpg';
import Divider from '@material-ui/core/Divider';
import {
  BrowserRouter as Router, Route, NavLink, Switch, Link, Redirect
} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

import { Image, Col, Row } from 'react-bootstrap';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import CreateIcon from '@material-ui/icons/Create';

import {
  isMobileOnly, isIE, isEdge, isSafari
} from "react-device-detect";

import * as firebase from 'firebase';

import chatIcon from '../coffeeIcon.jpg';
import kakaoIcon from '../kakaoIcon.png';

import Login from './Login';
import Footer from './Footer';

const axios = require("axios");

class OrderList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      userInfo: this.props.userInfo,

      gotInvoiceData: {}
    }
  }


  componentWillMount() {
    if (this.props.onRefreshUserInfo) {
      this.props.onRefreshUserInfo()
    }
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.isLoading === false) {
      if (nextProps.userInfo) {
        this.setState({ userInfo: nextProps.userInfo, isLoading: false });
      } else {
        this.props.history.replace('/login?redirect=/order_list');
        setTimeout(() => {
          this.props.routeChanges()
        }, 10)
      }
    } else {
      this.setState({ isLoading: true });
    }
  }

  onDateToTimeStamp(date) {
    let changedDate = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8)
    let timestamp = new Date(changedDate).getTime()
    return timestamp
  }

  onChangeCurrencyFormat(value) {
    return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  onSheetNumberToDate(sheetNumber) {
    let stringNumber = sheetNumber.toString()
    return stringNumber.substring(0, 4) + "." + stringNumber.substring(4, 6) + "." + stringNumber.substring(6, 8)
  }


  render() {
    if (this.state.isLoading) {
      return <div></div>
    }
    let sconeOrders = null
    let itemOrders = null
    let orders = null

    if (this.state.userInfo.sconeOrders) {
      sconeOrders = Object.values(this.state.userInfo.sconeOrders)
      sconeOrders = sconeOrders.filter(sconeOrders => sconeOrders.paid === true)
      if (sconeOrders.length !== 0) {
        sconeOrders.sort((a, b) => {
          return b.purchasedAt - a.purchasedAt
        })
      } else {
        sconeOrders = null
      }
    }
    if (this.state.userInfo.itemOrders) {
      itemOrders = Object.values(this.state.userInfo.itemOrders)
      itemOrders = itemOrders.filter(itemOrders => itemOrders.paid === true)
      if (itemOrders.length !== 0) {
        itemOrders.sort((a, b) => {
          return b.purchasedAt - a.purchasedAt
        })
      } else {
        itemOrders = null
      }
    }

    if (sconeOrders && itemOrders) {
      orders = sconeOrders.concat(itemOrders);
    } else if (!sconeOrders && itemOrders) {
      orders = itemOrders
    } else if (sconeOrders && !itemOrders) {
      orders = sconeOrders
    } else {
      orders = null
    }


    if (orders) {
      orders.sort((a, b) => {
        return b.purchasedAt - a.purchasedAt
      })
    } else {
      orders = null
    }

    let gotInvoiceData = this.state.gotInvoiceData

    return (
      <Grid  style={{paddingBottom: 60}}>
        <Grid container className="back-button-container" justify="center"  >
          <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 20}}>주문/배송</p>
          <Link
            to={'/mypage'}
            className="no-selection back-button"
            style={{position: 'absolute', left: 20, top: 20,}}
            onClick={() => {
              setTimeout(() => {
                this.props.routeChanges()
                if (isEdge || isIE) {
                    window.scrollTo(0, 0)
                } else {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                  })
                }
              }, 10)
            }}
          >
            <ArrowBackIosIcon  style={{color: '#5e5e5e', fontSize: 22}} />
          </Link>
        </Grid>
        <Grid style={{marginBottom: 20}}>

        { orders
          ?<div style={{marginTop: 70}}>
            { orders.map((order, index) => {
              if (order.invoiceNumber && !gotInvoiceData[order.orderId]) {
                gotInvoiceData[order.orderId] = true
                this.setState({gotInvoiceData: gotInvoiceData})
                if (!order.hasDelivered) {
                  if (order.trackingTime) {
                    if (order.trackingTime + (1000 * 60 * 30) < Date.now()) {
                      let providerCode = '06'
                      if (order['providerCode']) {
                        providerCode = order['providerCode']
                      }
                      axios({
                        url: `https://info.sweettracker.co.kr/api/v1/trackingInfo?t_key=KlWGLKFpuWCqsT0N55cYDw&t_code=${providerCode}&t_invoice=${order.invoiceNumber}`,
                        method: "get",
                        headers: {
                          "Content-Type":"application/json"
                        }
                      }).then((data) => {
                        if (order.orderSheet) {
                          let userInfo = this.state.userInfo
                          if (data.data.status !== false) {
                            userInfo.sconeOrders[order.orderId]['deiliveryInfo'] = data.data
                          }
                          if (data.data.level === 6) {
                            firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${order.orderId}/hasDelivered`).set(true)
                            firebase.database().ref(`orderSheets/${order.orderSheet}/orders/${order.orderId}/hasDelivered`).set(true)
                            if (data.data.status !== false) {
                              firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                              firebase.database().ref(`orderSheets/${order.orderSheet}/orders/${order.orderId}/deiliveryInfo`).set(data.data)
                            }

                            userInfo.sconeOrders[order.orderId]['hasDelivered'] = true
                          } else {
                            firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${order.orderId}/trackingTime`).set(Date.now())

                            firebase.database().ref(`orderSheets/${order.orderSheet}/orders/${order.orderId}/trackingTime`).set(Date.now())
                            if (data.data.status !== false) {
                              firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                              firebase.database().ref(`orderSheets/${order.orderSheet}/orders/${order.orderId}/deiliveryInfo`).set(data.data)
                            }

                          }
                          this.setState({userInfo: userInfo}, () => {
                            setTimeout(() => {
                              this.props.onRefreshUserInfo()
                            }, 10)
                          })
                        } else {
                          let userInfo = this.state.userInfo
                          if (data.data.status !== false) {
                            userInfo.itemOrders[order.orderId]['deiliveryInfo'] = data.data
                          }
                          if (data.data.level === 6) {
                            firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/hasDelivered`).set(true)
                            firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/hasDelivered`).set(true)
                            if (data.data.status !== false) {
                              firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                              firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/deiliveryInfo`).set(data.data)
                            }

                            userInfo.itemOrders[order.orderId]['hasDelivered'] = true
                          } else {
                            firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/trackingTime`).set(Date.now())

                            firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/trackingTime`).set(Date.now())
                            if (data.data.status !== false) {
                              firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                              firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/deiliveryInfo`).set(data.data)
                            }

                          }
                          this.setState({userInfo: userInfo}, () => {
                            setTimeout(() => {
                              this.props.onRefreshUserInfo()
                            }, 10)
                          })
                        }

                      })
                    }
                  } else {
                    let providerCode = '06'
                    if (order['providerCode']) {
                      providerCode = order['providerCode']
                    }
                    axios({
                      url: `https://info.sweettracker.co.kr/api/v1/trackingInfo?t_key=KlWGLKFpuWCqsT0N55cYDw&t_code=${providerCode}&t_invoice=${order.invoiceNumber}`,
                      method: "get",
                      headers: {
                        "Content-Type":"application/json"
                      }
                    }).then((data) => {
                      if (order.orderSheet) {
                        let userInfo = this.state.userInfo
                        if (data.data.status !== false) {
                          userInfo.sconeOrders[order.orderId]['deiliveryInfo'] = data.data
                        }
                        if (data.data.level === 6) {
                          firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${order.orderId}/hasDelivered`).set(true)
                          firebase.database().ref(`orderSheets/${order.orderSheet}/orders/${order.orderId}/hasDelivered`).set(true)
                          if (data.data.status !== false) {
                            firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                            firebase.database().ref(`orderSheets/${order.orderSheet}/orders/${order.orderId}/deiliveryInfo`).set(data.data)
                          }

                          userInfo.sconeOrders[order.orderId]['hasDelivered'] = true
                        } else {
                          firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${order.orderId}/trackingTime`).set(Date.now())
                          firebase.database().ref(`orderSheets/${order.orderSheet}/orders/${order.orderId}/trackingTime`).set(Date.now())
                          if (data.data.status !== false) {
                            firebase.database().ref(`users/${userInfo.uid}/sconeOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                            firebase.database().ref(`orderSheets/${order.orderSheet}/orders/${order.orderId}/deiliveryInfo`).set(data.data)
                          }

                        }
                        this.setState({userInfo: userInfo}, () => {
                          setTimeout(() => {
                            this.props.onRefreshUserInfo()
                          }, 10)
                        })
                      } else {
                        let userInfo = this.state.userInfo
                        if (data.data.status !== false) {
                          userInfo.itemOrders[order.orderId]['deiliveryInfo'] = data.data
                        }
                        if (data.data.level === 6) {
                          firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/hasDelivered`).set(true)
                          firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/hasDelivered`).set(true)
                          if (data.data.status !== false) {
                            firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                            firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/deiliveryInfo`).set(data.data)
                          }

                          userInfo.itemOrders[order.orderId]['hasDelivered'] = true
                        } else {
                          firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/trackingTime`).set(Date.now())
                          firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/trackingTime`).set(Date.now())
                          if (data.data.status !== false) {
                            firebase.database().ref(`users/${userInfo.uid}/itemOrders/${order.orderId}/deiliveryInfo`).set(data.data)
                            firebase.database().ref(`itemOrders/${order.purchasedDate}/${order.orderId}/deiliveryInfo`).set(data.data)
                          }

                        }
                        this.setState({userInfo: userInfo}, () => {
                          setTimeout(() => {
                            this.props.onRefreshUserInfo()
                          }, 10)
                        })
                      }

                    })
                  }

                }
              }
              return (
                <Link className="none-underline-text order-list-card"  onClick={() => {
                  setTimeout(() => {
                    this.props.routeChanges()
                  }, 10)
                }} to={order.orderSheet ? `/order/${order.orderId}` : `/item_order/${order.orderId}`}>
                <Grid container  style={{padding: 20, paddingBottom: 10}}  direction="row">
                  <Grid item xs={3}>
                    <Image style={{width: 80, height: 80}} src={Object.values(order.items)[0].image} />
                  </Grid>
                  <Grid item xs={6} style={{marginLeft: 10}}>
                    { order.orderSheet
                      ?<p style={{fontSize: 13, margin: 0, padding: 0, fontWeight: 'bold', marginBottom: 5}}>{this.onSheetNumberToDate(order.orderSheet)}</p>
                      :<p style={{fontSize: 13, margin: 0, padding: 0, fontWeight: 'bold', marginBottom: 5}}>{this.onSheetNumberToDate(order.purchasedDate)}</p>
                    }
                    { order.totalAmount !== 1
                      ?<p className="one-line" style={{fontSize: 13, margin: 0, padding: 0, width: 180}}>{Object.values(order.items)[0].name} 외 {order.totalAmount - 1}건</p>
                      :<p className="one-line" style={{fontSize: 13, margin: 0, padding: 0, width: 180}}>{Object.values(order.items)[0].name}</p>
                    }
                    { order.paidPrice
                      ?<p style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, marginBottom: 5}}>{this.onChangeCurrencyFormat(order.paidPrice)}원 결제</p>
                      :<p style={{fontSize: 12, margin: 0, padding: 0, marginTop: 5, marginBottom: 5}}>{order.usingCoupon ? this.onChangeCurrencyFormat(Math.max(order.totalPrice + order.deliveryCharge - order.usingCoupon.amount, 0)) : this.onChangeCurrencyFormat(order.totalPrice + order.deliveryCharge)}원 결제</p>
                    }

                    { order['orderSheet'] &&
                    <div>
                    { order.invoiceNumber
                      ?order.hasDelivered
                      ?<p style={{fontSize: 13, margin: 0, padding: 0, fontWeight: 'bold'}}>배송완료</p>
                      :<p style={{fontSize: 13, margin: 0, padding: 0, fontWeight: 'bold'}}>배송중</p>
                      :!(this.onDateToTimeStamp(order['orderSheet'].toString()) + (1000 * 60 * 60 * 25) < Date.now())
                      ?<p style={{fontSize: 13, margin: 0, padding: 0, fontWeight: 'bold'}}>발주확인 전</p>
                      :<p style={{fontSize: 13, margin: 0, padding: 0, fontWeight: 'bold'}}>상품준비중</p>
                    }
                    </div>
                    }
                    { order['purchasedDate'] &&
                    <div>
                    { order.invoiceNumber
                      ?order.hasDelivered
                      ?<p style={{fontSize: 13, margin: 0, padding: 0, fontWeight: 'bold'}}>배송완료</p>
                      :<p style={{fontSize: 13, margin: 0, padding: 0, fontWeight: 'bold'}}>배송중</p>
                      :!(this.onDateToTimeStamp(order['purchasedDate'].toString()) + (1000 * 60 * 60 * 25) < Date.now())
                      ?<p style={{fontSize: 13, margin: 0, padding: 0, fontWeight: 'bold'}}>발주확인 전</p>
                      :<p style={{fontSize: 13, margin: 0, padding: 0, fontWeight: 'bold'}}>상품준비중</p>
                    }
                    </div>
                    }

                  </Grid>

                </Grid>
                </Link>
              )
            })
            }
          </div>
          :<div className="no-items-main-view">
            <LocalShippingOutlinedIcon  style={{color: '#a1a1a1', fontSize: 80}} />
            <span style={{color: '#a1a1a1', fontSize: 16, margin: 2}}>주문 내역이 없습니다.</span>
          </div>
        }
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(OrderList);
